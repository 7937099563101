export default function bulkUsersUpdateReducer
  (state = { error:'', success:'', processing:false, progress:0}, action)
{
  switch (action.type) {
    case 'BULK_USERS_UPDATE_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'BULK_USERS_UPDATE_ERROR':
      return {...state, ...{error: action.error}};

    case 'BULK_USERS_UPDATE_PROCESSING':
      return {...state, ...{processing: action.processing}};

    case 'BULK_USERS_UPDATE_PROGRESS':
      return {...state, ...{progress: action.progress}};


    case 'BULK_USERS_UPDATE_RESET':
      return { ...state, ...{
          success: null,
          error: null,
          processing:false,
          progress:0
        }
      };
    default: return state;
  }
}