import React from 'react';
import {names} from "../../../utils/StringUtils";

const TableHead = (props) => {

    let {fields, params, onClick, noActions} = props;
    return (

        <thead>
        <tr>
            {fields.map(field => {
                return (

                    <th scope="col"
                        key={field.field}
                        className={names(
                            params.sort_column === field.field && params.sort_by === `desc` && 'th-active-desc',
                            params.sort_column === field.field && params.sort_by === `asc` && 'th-active-asc',
                            field.sort && `th-sortable`
                        )}
                        onClick={() => field.sort && onClick && onClick(field.field)}>{field.label}</th>
                )
            })
            }
            {!noActions && <th className="actions">Åtgärder</th>}
        </tr>
        </thead>

    )
}
export default TableHead;