import React from 'react';
import {Helmet} from 'react-helmet';
import {ConfigApp} from "../../../Config";

const Page = (props) => {
    let {title, children} = props;
    let {Name} = ConfigApp;
    return (
        <div className="Page animated fadeIn faster">
            <Helmet>
                <title>{title} - {Name}</title>
            </Helmet>
            {children}
        </div>
    )
}
export default Page;