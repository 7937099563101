import React, {useState, useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams,} from 'react-router';
import getRole, {getRoleReset} from '../../../../ajax/actions/role/role_get'
import {
    setDefaultRoleForm,
    setRoleDataForUpdate
} from './RoleForm-Action'
import {endOfURL, urlTrailWithoutLastItem} from "../../../../utils/URLUtils";
import {createRoleReset} from "../../../../ajax/actions/role/role_post";
import {updateRoleReset} from "../../../../ajax/actions/role/role_put";

const RoleFormWorker = () => {
    const reduxState = useSelector(state => state);
    const {roleGet, roleUpdate, roleCreate } = reduxState;
    let dispatch = useDispatch();
    const history = useHistory();
    let {id} = useParams();

    useEffect(() => {
        id && dispatch(getRole(id));
        if (endOfURL() === 'create') {
            dispatch(setDefaultRoleForm());
        }

        return () => {
            dispatch( getRoleReset() )
        }
    }, []);

    useEffect(() => {
        if (roleGet.success) {
            dispatch(setRoleDataForUpdate());
        }
    }, [roleGet.success]);

    useEffect(() => {
        if (roleUpdate.success) {
            dispatch(updateRoleReset());
        }
    }, [roleUpdate.success]);

    useEffect(() => {
        if (roleCreate.success) {
            dispatch(createRoleReset());
            history.push(urlTrailWithoutLastItem());
        }
    }, [roleCreate.success]);

    return null
}

export default RoleFormWorker;