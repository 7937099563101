import React, {useState} from 'react';
import {names, ucFirst, unslufigy} from "../../../utils/StringUtils";
import MyIcon from "../icons/MyIcon";
import Badge from "../badges/Badge";
import uploadIcon from "../../../assets/images/cloud_upload.svg"
import Div from "../../common/Div/Div";

const UploadField = (props) => {
    const [files, setFiles] = useState([]);
    const {
        value, name, label, onChange, noLabel, labelIcon, required, id, touched, error,
        multiple, onRemoveImage, uploadType, supportedFormats, noFileRemoval,
    } = props;

    const hasError = error && touched;

    const Label = () => {
        if (label) return (
            <Div className={names(`label-text`)}>
                {label} {hasError ? error + '.' : null}
            </Div>
        );
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return (
                    <Div className={names(`label-text`)}>
                        {_label} {hasError ? error + '.' : null}
                    </Div>
                )
            }
        }
        return null
    }

    const removeFile = (name) => {
        let files = {};
        Object.keys(value).map(key => {
            if (value[key].name !== name) {
                files[key] = value[key];
            }
        })
        onChange({target: {name, value: files}})
    }
    const removeSingleFile = () => {

        onChange({target: {name, value: ``}})
    }

    return (
        <div
            className={names(`form-group`, `animated fadeIn faster`, hasError && `has-error`, required && `required`)}>

            { hasError && <Label/>}
            <div className="input-file-wrapper">
                <input
                    type="file"
                    onChange={e => onChange({
                        target: {
                            name,
                            value: e.target.files,id,
                            files: files.concat(Array.from(e.target.files))
                        }
                    })}
                    id="fileUpload"
                    // className="custom-file-input"
                    multiple={multiple}
                />
            </div>

            <div className="info-msg">
                <label htmlFor="fileUpload" className="uploader-custom">
                    <img src={uploadIcon} alt=""/>
                    <p>Attach image/file</p>
                    {supportedFormats ?
                        <p className="fileType">
                            Only {supportedFormats} formats are allowed.
                        </p>
                        : null}
                </label>
            </div>

            {value && Object.keys(value).length ?
                <div className="pd-2">
                    <ul className="file-list">
                        {
                            value && Object.keys(value).map(key => {

                                return (

                                    <li key={key}>
                                        <Badge type="secondary" style={{marginRight: 10}}>
                                            {value[key].name}
                                            {!noFileRemoval && (
                                                <MyIcon type="times"
                                                        // onClick={() => removeFile(value[key].name)}
                                                        onClick={onRemoveImage}
                                                        className="remove-file"
                                                />
                                            )}
                                        </Badge>
                                    </li>
                                )

                            })
                        }
                    </ul>

                </div>
                :
                null
            }
            {/*<FileManager
                    files={this.state.files}
                >
                    {this.uploadFiles}
                </FileManager>*/}

        </div>
    )
}
export default UploadField;