import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import getRoles from "../../../../ajax/actions/role/roles_get";

const RoleWorker = () => {
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRoles());
    }, [])
    return null
}

export default RoleWorker;