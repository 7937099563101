export function setDefaultRoleForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-ROLE-FORM'});
  }
}

export function setRoleDataForUpdate() {
  return function (dispatch, getState) {
    let { roleGet } = getState();
    let roles = roleGet && roleGet.success && roleGet.success.data;
    if(roles) {
      dispatch({type:'SET-ROLE-DATA-FOR-UPDATE',  roles:JSON.parse(JSON.stringify(roles)) });
    }
  }
}