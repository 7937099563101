export default function deleteUserReducer
  (state = { error:'', success:'', processing:false}, action)
{
  switch (action.type) {
    case 'DELETE_USER_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'DELETE_USER_ERROR':
      return {...state, ...{error: action.error}};

    case 'DELETE_USER_PROCESSING':
      return {...state, ...{processing: action.processing, id:action.id}};

    default: return state;
  }
}