const DefaultRoleListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_column:  'id',
    sort_by: `desc`,
    q: ``,
  },
  modal: {
    mode: ``,
    id: ``
  },
  fields: [
    { field: `id`, label: `ID`, sort: true },
    { field: `title`, label: `Status`, sort: true },
    { field: `slug`, label: `Slug`, sort: true },
  ]
};

export const RoleListState = { data: DefaultRoleListState };