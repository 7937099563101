import React, { useEffect, memo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import getUserStats from "../../../../ajax/actions/user/user_stats_get";
const UserStats = () => {

    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {  userStatsGet } = reduxState;

    useEffect(() => {
        dispatch( getUserStats() );
    },[]);

    // console.log(` <| userStatsGEt |> `,userStatsGet  );
    const stats = userStatsGet && userStatsGet.success && userStatsGet.success.data;

    return (
        <div className="stat-block">
            <div className="single-stat">
                <div className="title-block">Totalt antal medlemmar</div>
                <div className="stat-info">
                    <div className="number-block">{stats.total_users}</div>
                    {/*<div className="percent-block text-success">125%</div>*/}
                </div>
            </div>
            <div className="single-stat">
                <div className="title-block">Nya medlemmar per år</div>
                <div className="stat-info">
                    <div className="number-block">{ stats.new_users_this_year }</div>
                    {/*<div className="percent-block text-success">100%</div>*/}
                </div>
            </div>
            <div className="single-stat">
                <div className="title-block">Nya medlemmar per månad</div>
                <div className="stat-info">
                    <div className="number-block">{ stats.new_users_this_month }</div>
                    {/*<div className="percent-block text-danger">-10%</div>*/}
                </div>
            </div>
        </div>
    )
}

export default memo(UserStats);