import { UserFormState } from './UserForm-State'

export default function userFormReducer(state = UserFormState, action) {

  let { payload } = { ...state };
  let { users } = action;
  switch (action.type) {

    case 'SET-USER-DATA-FOR-UPDATE':
      payload = {
        first_name: users && users.first_name,
        last_name: users && users.last_name,
        email: users && users.email,
        username: users && users.username,
        // phone_no: users && users.phone_no,
        password: users && users.password,
        address:users && users.address,
        personal_number:users && users.personal_number,
        post_address:users && users.post_address,
        grad:users && users.grad,
        forb:users && users.forb,
        offk:users && users.offk,
        stand:users && users.stand,
        samtycke:!!(users && users.samtycke === 1),

        // company_name:users && users.company_name,
        // second_address_line:users && users.second_address_line,
        postal_code:users && users.postal_code,
        // city:users && users.city,
        // state:users && users.state,
        // country:users && users.country,
          role: users && users.role.slug,
        status: users && users.status,
          note:users && users.note,
          payment_status:users && users.payment_status,
          // member:users && users.member,
      };
      return { ...state, payload };

    case 'SET-DEFAULT-USER-FORM':

        payload={
            first_name: ``,
            last_name: ``,
            username:``,
            email: ``,
            address:``,
            // company_name:``,
            // second_address_line:``,
            postal_code:``,
            post_address:``,
            grad:``,
            forb:``,
            offk:``,
            stand:``,
            samtycke:false,
            // city:``,
            // state:``,
            // country:``,
            password: ``,
            password_confirmation: ``,
            role: ``,
            note:``,
            payment_status:``
            // member:``,
      };
      return { ...state, payload };

    default:
      return state
  }


}

