import React from 'react';
import Loading from "../loaders/Loading";

const SaveButton = (props) =>
{

        let {  processing, text, btnClass, style, type } = props;
        return (

            <button className={`btn-theme btn-blue ${btnClass}`} type={ type || 'button' } disabled={processing ? true : null} style={ style }>
                { processing ? <Loading xs color="white" /> : text || 'SPARA' }
            </button>

        )
}
export default SaveButton;