export const limitOptions = [
  // { total: 2, label: `2 Results` },
  { total: 10, label: `10 resultat` },
  { total: 25, label: `25 resultat` },
  { total: 50, label: `50 resultat` },
  { total: 100, label: `100 resultat` },
  { total: 500, label: `500 resultat` },
];

export const fewerLimitOptions = [
    { total: 25, label: `25 resultat` },
    { total: 50, label: `50 resultat` },
    { total: 100, label: `100 resultat` },
    { total: 500, label: `500 resultat` },
];

export const deviceTypeOptions = [
    { slug: `ios`, label: `iOS` },
    { slug: `web`, label: `Web` },
    { slug: `android`, label: `Android` }
]

export const appTypeOptions = [
    { slug: `customer`, label: `Customer App` },
    { slug: `driver`, label: `Driver App` },
]

export const userClassOptions = [
  { value: 30, label: `General` },
  { value: 10, label: `VIP` }
];

export const statusOption = [
    { value: `pending`, label: `Pending` },
    { value: `approved`, label: `Approved` },
]

export const samtyckeOption = [
    { value: false, label: `False` },
    { value: true, label: `True` },
]

export const paymentOptions = [
    { value: 'paid', label: `Betald` },
    { value: 'unpaid', label: `Obetald` },
]