import axios from 'axios'
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from "../../../utils/LocalStorageUtils";
import {handleError} from "../../../utils/ErrorUtils";

function _success(success) {
    return {type: 'GET_USER_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_USER_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_USER_PROCESSING', processing};
}

function getUser(id) {
    return (dispatch,) => {
        dispatch(_processing(true));

        axios({
            url:apiBaseUrl(`user/${id}`),
            method:'get',
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });
    }
}
export function getUserReset()
{
    return dispatch => dispatch({ type: `GET_USER_RESET` })
}
export default getUser;