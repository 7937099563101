import axios from 'axios'
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from "../../../utils/LocalStorageUtils";
import {handleError} from "../../../utils/ErrorUtils";
import {clone} from "../../../utils/DataUtils";

function _success(success) {
    return {type: 'GET_DELETION_REQUESTS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_DELETION_REQUESTS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_DELETION_REQUESTS_PROCESSING', processing};
}

function getDeletionRequests( )
{
    return (dispatch, getState) =>
    {
        dispatch(_processing(true));
        let { deleteRequestListReducer } = getState();

        let _params = clone( deleteRequestListReducer.data.params );

        axios({
            url:apiBaseUrl(`requests/deletion`),
            method:'get',
            params: _params,
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });
    }
}

export default getDeletionRequests;