import store from '../../../../store';
import getRoles from '../../../../ajax/actions/role/roles_get'

export function toggleRoleListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:'TOGGLE-ROLE-LIST-FILTER', name, value});
    dispatch( getRoles());
  }
}

export function toggleRoleFormModal( id )
{
  return function (dispatch) {
    dispatch({type:'TOGGLE-ROLE-FORM-MODAL', id });
  }
}
