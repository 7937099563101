import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import getUser, {getUserReset} from '../../../../ajax/actions/user/user_get'
import {
    setDefaultUserForm,
    setUserDataForUpdate
} from './UserForm-Action'
import {useHistory, useParams} from "react-router";
import getRoles from "../../../../ajax/actions/role/roles_get";
import {createUserReset} from "../../../../ajax/actions/user/user_post";
import {updateUserReset} from "../../../../ajax/actions/user/user_put";
import {urlTrailWithoutLastItem} from "../../../../utils/URLUtils";
import {scrollToError} from "../../../../utils/ScrollUtils";
const UserFormWorker = () => {

    let dispatch = useDispatch();
    const history = useHistory();
    const reduxState = useSelector(state => state);
    const {userCreate, userGet, userUpdate} = reduxState;
    let {id} = useParams();

    useEffect( () => {
        dispatch( setDefaultUserForm() );
        dispatch( getRoles(100) );
        id && dispatch( getUser(id) );

        return () => {
            dispatch( getUserReset() );
        }
    }, []);


    useEffect( () => {
        if ( userCreate && userCreate.success ) {
            dispatch( createUserReset() );
            dispatch( setDefaultUserForm() );
            history.push(urlTrailWithoutLastItem());
        }
    }, [userCreate.success]);

    useEffect( () => {
        if ( userCreate && userCreate.error ) {
            scrollToError()
        }
    }, [userCreate.error]);

    useEffect( () => {
        if ( userUpdate && userUpdate.error ) {
            scrollToError()
        }
    }, [userUpdate.error]);

    useEffect( () => {
        if ( userUpdate && userUpdate.success ) {
            dispatch( updateUserReset() );
            if( history.location && history.location.pathname.includes('pending') ) {
                // history.push('/pending-users');
                history.push(`/pending-users/${id}/view`);
            } else {
                // history.push('/users');
                history.push(`/users/${id}/view`);
            }

        }
    }, [userUpdate.success]);

    useEffect( () => {
        userGet.success && dispatch(setUserDataForUpdate( userGet.success ))
    },[userGet.success]);

    return null
}

export default UserFormWorker;