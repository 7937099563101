import React, { useState, useEffect } from 'react';
import RoleWorker from "./RoleWorker";
import { useDispatch, useSelector} from "react-redux";
import swal from "sweetalert";
import deleteRole from "../../../../ajax/actions/role/role_delete";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import RoleListFilters from "./RoleListFilters";
import { toggleRoleListFilter} from "./RoleList-Actions";
import Loading from "../../../shared/loaders/Loading";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import TableHead from "../../../shared/table/TableHead";
import Page from "../../../shared/pages/Page";
import {Link, useRouteMatch} from "react-router-dom";
import ActionCell from "../../../shared/table/ActionCell";

const Roles = () => {
    const [initialLoading, setInitialLoading] = useState(true);

    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {rolesGet, roleList, roleDelete} = reduxState;

    useEffect(() => {
        if (rolesGet.success && initialLoading) {
            setInitialLoading(false);
        }
    }, [rolesGet.success])

    const deleteConfirm = (id) => {
        swal("Är du säker på att du vill radera denna roll? ", {
            buttons: {
                cancel: "Avbryt",
                catch: {
                    text: "Radera",
                    value: id
                }
            }
        }).then((value) => {
            if (value) dispatch(deleteRole(id))
        })
    }


    const toggleSort = (field) =>
    {
        dispatch(  toggleRoleListFilter({
            target: { name: `sort_by`, value: field }
        }))
    };

    let list =
        rolesGet.success &&
        rolesGet.success.data.data;

    let current_page = rolesGet.success && rolesGet.success.data && rolesGet.success.data.current_page;
    let last_page = rolesGet.success && rolesGet.success.data && rolesGet.success.data.last_page;
    let total = rolesGet.success && rolesGet.success.data && rolesGet.success.data.total;
    let pages = {current_page, last_page, total}

    let { params, fields } = roleList.data;
    let {url} = useRouteMatch();

    return (
        <Page title="Roller">
            <RoleWorker/>
            <div className="page-title">
                <h2>Behörighet { rolesGet.processing && <Loading marginLeft={20}/> }</h2>
                <div className="button-block">
                    <Link to={`/roles/create`}>
                        <button type="button"
                            className="btn-theme btn-gray">Skapa ny behörighet
                        </button>
                    </Link>
                </div>
            </div>

            <RoleListFilters />

            <div className="table-dashboard table-small">
                <div className="table-responsive">

                    <table className="table">

                        <TableHead fields={ fields }
                                   params={ params }
                                   onClick={ toggleSort } />

                        <tbody>
                        {

                            list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{l.id}</td>
                                        <td className="clickable-col"
                                            >{l.title}</td>
                                        <td>{l.slug}</td>
                                        <td className="actions">
                                            <ActionCell id={l.id}
                                                    url={url}
                                                    role=""
                                                    onDelete={ deleteConfirm }
                                                    deleteReducer = { roleDelete }
                                            />
                                        </td>
                                        {/*<td>
                                            <Link to={`${url}/${l.id}/update`}>
                                                <button className="btn btn-secondary btn-sm">
                                                    <MyIcon type="edit" />
                                                </button>
                                            </Link>
                                            <button type="button"
                                                    className="btn btn-danger btn-sm ml-1"
                                                    onClick={() => deleteConfirm(l.id)}>
                                                {roleDelete.processing && roleDelete.id === l.id ? <GrowingLoader /> : <MyIcon type="trash" /> }
                                            </button>
                                        </td>*/}
                                    </tr>
                                )
                            }) : null
                        }
                        </tbody>
                        { list && list.length ? (
                            <tfoot>
                            <PaginationButtons pages={pages}
                                               processing={rolesGet.processing}
                                               fetchAction={toggleRoleListFilter} />
                            </tfoot>
                        ) : null }
                    </table>
                    { !initialLoading && ( !list || !list.length) && <NoResultsFound/> }
                    { initialLoading && <div className="text-center"><Loading/></div> }
                </div>
            </div>


        </Page>
    )
}


export default Roles;