import React from 'react';
import {Link} from "react-router-dom";
import swal from "sweetalert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import ActionCell from "../../../shared/table/ActionCell";
import { ucFirstAll} from "../../../../utils/StringUtils";
import Badge from "../../../shared/badges/Badge";
import deleteUser from "../../../../ajax/actions/user/user_delete";
import approveUser from "../../../../ajax/actions/user/user_approve_post";
import {useDispatch, useSelector} from "react-redux";
import deleteRequestConfirm from '../../../../ajax/actions/deletion-request/deletion_request_confirm_post';
import MyIcon from '../../../shared/icons/MyIcon';
import GrowingLoader from '../../../common/loaders/GrowingLoader';
// import {justDateCustomFormatted} from "../../../../utils/DateTimeUtils";

const DeleteRequestTableCell = (props) => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { postDeletionRequestConfirmReducer } = reduxState;

    const statusConfirm = (id) => {
        // Är du säker på att du vill ${ status === 'approved' ? 'pending' : 'godkänna' } denna användare?
        swal(`Är du säker på att du vill ändra status på denna användare till? `, {
            buttons: {
                cancel: "Avbryt",
                catch: {
                    text: "Bekräfta",
                    value: id
                }
            }
        }).then((value) => {
            if (value) dispatch(deleteRequestConfirm(id))
        })
    };

    const { data} = props;
    return (
        <>
            <td >{ucFirstAll( data.user.first_name.trim() )} </td>
            <td > { ucFirstAll( data.user.last_name.trim() )}</td>
            <td className="note-width">{data.user.address}</td>
            <td className="note-width">{data.user.post_address}</td>
            <td>{data.user.postal_code || '-'}</td>
            <td>{data.user.email}</td>
            <td>{data.user.personal_number}</td>
            
            <td>
                <Badge type={data.user.status}>{data.user.status === 'approved' ? 'Godkänd' : 'Väntar' }</Badge></td>
            <td className="actions">
                {data.status !== 'pending' ? <Badge type='danger'>{data.status }</Badge>
                    
                    : <button type="button"
                        className={`btn  btn-sm ml-1 btn-success`}
                        onClick={() => statusConfirm(data.id)}
                        
                        data-tip="Toggle">
                        {postDeletionRequestConfirmReducer.processing && data.id === postDeletionRequestConfirmReducer.id ?
                            <GrowingLoader /> : <MyIcon type="check" />}
                    </button>}
            </td>
        </>
    )
}

export default DeleteRequestTableCell;