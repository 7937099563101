import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { limitOptions, statusOption} from '../../../../static-data/LimitOptions'
import {toggleUserListFilter, setDefaultUserFilters, setUserStatus} from './UserList-Actions'
import getRoles from "../../../../ajax/actions/role/roles_get";
import getUsers from "../../../../ajax/actions/user/users_get";
import {useHistory} from "react-router";
import SelectField from "../../../common/form/SelectField";
import TextField from "../../../common/form/TextField";

const UsersFilters = (props) => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const history = useHistory();
    const {userList, rolesGet} = reduxState;

    useEffect(() => {

        dispatch(getRoles(100));
    }, [])


    const toggleFilter = (e) => {
        dispatch(toggleUserListFilter(e, history));
    };

    const resetFilter = (e) => {
        dispatch(setDefaultUserFilters(e, history));
        dispatch(setUserStatus(props.status));
        dispatch(getUsers());
    };

    const applyFilters = (e) => {
        dispatch(getUsers());
    };
    let roles = rolesGet.success && rolesGet.success.data.data;
    const {limit, q, role_id, status } = userList.data.params;

    return (

        <div className="table-filter mb-5">
            <div className="row">
                <div className="col custom-col">
                    <SelectField name="limit"
                                 noFormGroup
                                 options={limitOptions}
                                 _value="total"
                                 _label="label"
                                 value={limit}
                                 notSearchable
                                 placeholder="-- Limit --"
                                 onChange={toggleFilter}
                                 label="Begränsning"/>
                                 
                </div>

                <div className="col custom-col">
                    <SelectField clearable
                                 name="role_id"
                                 noFormGroup
                                 options={roles}
                                 _value="id"
                                 _label="title"
                                 value={role_id}
                                 placeholder="-- Role --"
                                 onChange={toggleFilter}
                                 label="Status"/>
                                 
                </div>

                {/*<div className="col custom-col">
                    <SelectField name="status"
                                 noFormGroup
                                 options={statusOption}
                                 _value="value"
                                 _label="label"
                                 value={status}
                                 notSearchable
                                 placeholder="-- Status --"
                                 onChange={toggleFilter}/>
                </div>*/}

                <div className="col custom-col">
                    <TextField name="q"
                               label="Sök"
                               placeholder="Nyckelord"
                               onChange={toggleFilter}
                               value={q}
                    />
                </div>


                {/*<div className="col custom-col">
                    <div className="text-right">
                        <button className="btn-theme btn-gray m-auto" onClick={applyFilters}>Filter</button>
                    </div>
                </div>
                <div className="col custom-col">
                    <div className="text-right mt-1">
                        <button className="btn-theme btn-gray m-auto" onClick={resetFilter}>Reset</button>
                    </div>
                </div>*/}

                <div className="col custom-col button-col">
                    <div className="button-cluster">
                        <button className="btn-theme btn-gray mr-2" onClick={applyFilters}>Filtrera</button>
                        <button className="btn-theme btn-gray " onClick={resetFilter}>Återställ</button>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default UsersFilters;