export default function updateMyAccountReducer
  (state = { error:'', success:'', processing:false}, action)
{
  switch (action.type) {
    case 'POST_ACCOUNT_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'POST_ACCOUNT_ERROR':
      return {...state, ...{error: action.error}};

    case 'POST_ACCOUNT_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'POST_ACCOUNT_RESET':
      return { ...state, ...{
          success: null,
          error: null,
          processing:false
        }
      };
    default: return state;
  }
}