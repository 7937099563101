// export const loginToken = () => `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNThiYWZkNTk4YjZhMWZiOGZkMjM4M2E4Yzg4ZDcwNzk2NmYzMWUxMDNlMjcyNGZlNzdkOWU2ZDNkMmExNmE3ZWQ4OWZjMDdhOGRkODdhMmQiLCJpYXQiOjE1OTc2NjI1NjEsIm5iZiI6MTU5NzY2MjU2MSwiZXhwIjoxNjI5MTk4NTYxLCJzdWIiOiIyIiwic2NvcGVzIjpbIioiXX0.SrN1kTyNMsqDDtdb8TIaS5a6zO3uiKqHiGfd1A-Xmr7Y9-iL4mdBUK1IsUs2cRDLLqZpZMzxwx6svrOgQf1Vy0C5ZK8UGnTBX8rKyi6Ie0LZgWX89KWpg8F1jHiuYk8BFo_Pyy0iKdmr3ereW-fJr5DNL16rlAi07dPAnXwPNBFl9ArN8WNsd3-z_7q_BZxkaYV0Bqgy2YIBxFmmwdv4nUmdBYNUCOQGxaLNYheBOEGendLSWV04pGOIN_czQt6ILM2FRpIWhjANyUhnD3x-GtmrcPKXU9ikk4UkUvBvH1Fw9jRffwGBNoCDuYJlVVJ-GywoWzjmOmSUDao2LAOrVKOzvQoKFegG3Rbu8eA4mWC3Wo_VNj8re4yKEBt45V23iM74X6oK1VKpT_JYuz2XDFle7qdks3Z0vOqcKd9IFIkDDRIdDV3swwo-jcUuMw_gLN-DNGkuBKkGu0YL6bXjsiAsPcJJiFrX_a-emSRiSB_JE7XT0p5K-uRGKHB0BN2Ir_GyxLU-AUwIgNN3MnsE7-o7jQJUnL-cdJmvJegdvLHdNvtogdRqipBZvSrLd48U3VIYfkNt82aJO_fRQGP7I0SbAqnyJYcCmNHgsH64Ncle3tMugqLpgLVDdXhMDYnIABMrnnHVWvUX_x0m_KJPTrYL2lLDkmEVjDv0vAAcVLg`;
// import { useHistory } from "react-router-dom";
/* Find out if anyone is logged in | @return boolean */
export const loggedIn   = () => !! localStorage.getItem('Bearer');
export const loginToken   = () =>  localStorage.getItem('Bearer');

export const loggedInUser = data =>
{
  localStorage.setItem('Bearer',     data.access_token);
  // localStorage.setItem('expires_in', data.expires_in);
};

export const login = () =>           localStorage.setItem(`Bearer`,)
export const logOut = ( history ) => {
  localStorage.removeItem('Bearer');
  if ( history ) history.push(`/login`);
  else window.location.href = ('/login');
}