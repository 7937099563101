import React from 'react';
import {slugify} from "../../../utils/StringUtils";

function Badge(props)
{
    let { type, children, className, round, curved, right, style } = props;
    return (
        <span
            className={`badge badge-${
                type ? slugify( type ).toLowerCase() : `default`} ${
                round ? `icon-sm-circle` : ''} ${
                curved ? `icon-sm-rounded` : ''} ${
            className || ''} ${
                right ? `pull-right` : ``}`}
            style={ style }>
                { children }
        </span>
    )
}
export default Badge;