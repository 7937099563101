import { AccountFormState } from './AccountForm-State'

export default function accountFormReducer(state = AccountFormState, action) {

  let { payload } = { ...state };
  let {  accounts } = action;
  switch (action.type) {

    case 'SET-ACCOUNT-DATA-FOR-UPDATE':
      payload = {
        first_name: accounts && accounts.first_name,
        last_name: accounts && accounts.last_name,
        email: accounts && accounts.email,
        username: accounts && accounts.username,
        address:accounts && accounts.address,
        post_address:accounts && accounts.post_address,
        personal_number:accounts && accounts.personal_number,
        grad:accounts && accounts.grad,
        forb:accounts && accounts.forb,
        offk:accounts && accounts.offk,
        stand:accounts && accounts.stand,
        samtycke:!!(accounts && accounts.samtycke === 1),
        // company_name:accounts && accounts.company_name,
        // second_address_line:accounts && accounts.second_address_line,
        // postcode:accounts && accounts.postcode,
        // city:accounts && accounts.city,
        // state:accounts && accounts.state,
        // country:accounts && accounts.country,
        role_id: accounts && accounts.role_id,
        role: accounts && accounts.role.slug,
        status: accounts && accounts.status,
        id:accounts && accounts.id,
        note:accounts && accounts.note,
        // member:accounts && accounts.member,
      };
      return { ...state, payload };
    default:
      return state
  }

}

