export default function getRoleReducer
  (state = { error:'', success:'', processing:false}, action)
{
  switch (action.type) {
    case 'GET_ROLE_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'GET_ROLE_ERROR':
      return {...state, ...{error: action.error}};

    case 'GET_ROLE_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'GET_ROLE_RESET':
      return { ...state, ...{
          success: null,
          error: null
        }
      };
    default: return state;
  }
}