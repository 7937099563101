import { DeleteRequestListState } from './DeleteRequestList-State'

export default function deleteRequestListReducer(state = DeleteRequestListState, action) {

  let { data } = { ...state };
  let { name, value, status, id } = action;

  switch (action.type) {

    case 'TOGGLE-DELETE-REQUEST-LIST-FILTER':
      data.params[ name ] = value;
      if ( name === `sort_by` || name === `sort_column` ) {
        if ( data.params.sort_column === value ) {
          data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
        }
      }
      if ( name === `limit` ) {
        data.params.page = 1;
      }
      return { ...state, data };

    case 'SET-DELETE-REQUEST-STATUS':
      data.params.status = status
      return { ...state, data };

    case 'SET-DEFAULT-DELETE-REQUEST-FILTERS':
      data = {
        payload: {
          initialLoading: true,
          flashMessage: null,
          error: "",
          success: "",
        },
        params: {
          limit: 25,
          page: 1,
          sort_by: 'desc',
          sort_column: `id`,
          q: ``,
          // status:``,
          // role_id: ``,
        },
       
        fields: [
          // { field: `id`, label: `ID`, sort: true },
          // { field: `username`, label: `Användarnamn`, sort: true },
          { field: `first_name`, label: `Förnamn`, sort: true },
          { field: `last_name`, label: `Efternamn`, sort: true },
          // { field: `role`, label: `Role`, sort: true },
          { field: `address`, label: `Adress`, sort: true },
          { field: `post_address`, label: `Postadress`, sort: true },
          { field: `postal_code`, label: `Postnummer`, sort: true },
          { field: `email`, label: `E-post`, sort: true },
          { field: `personal_number`, label: `Personnummer`, sort: true },
          { field: `status`, label: `Status`, sort: true },
        ]
      }
      return { ...state, data };

    default: return state
  }
}
