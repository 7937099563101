import React, { memo } from 'react'
import loader from '../../../assets/images/loader.svg'
import logo from '../../../assets/images/oisf_dark.svg'
import whiteLoader from '../../../assets/images/loader-white.svg'

const SuspenseLoader = (props) => {

    let {
        color, height, width,
        xs, sm, md, lg, xl,
    } = props;

    let setHeight = height || xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;
    let setWidth = width || xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;

    return (

        <div className="mt-40 ml-5">
            <img src={logo}
                 alt="logog"
                 height={40}/>
            <img src={color === `white` ? whiteLoader : loader}
                 alt="loader"
                 height={setHeight}
                 width={setWidth}
            />
        </div>
    );
}

export default memo(SuspenseLoader )