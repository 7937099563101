import React, { useState } from 'react';
import Loading from "../loaders/Loading";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
const PaginationButtons = ( props ) =>
{
  let dispatch = useDispatch();
  const [ loader, setLoader ] = useState(``);
  let history = useHistory();

  function onPageClick(button, page) {
    setLoader(button);
    let {fetchAction } = props;
    let e = {target: {name: `page`, value: page}}
    fetchAction && dispatch( fetchAction( e, history ))
  }

  const loaderAt = (button) => {
    let {processing} = props;
    return processing && loader === button;
  }
  
  let {pages} = props;
  let {current_page, last_page, total} = pages;

    let showFormerDots = current_page > 2 && current_page !== 3;
    let showRearDots = current_page !== last_page && current_page !== last_page - 1 && last_page - current_page > 2;

  if( current_page === 1 && last_page === 1 ) return null;

    if ( current_page && last_page ) //&& ( current_page !== last_page ) )
      return (

          <tr>
            <td colSpan={3} style={{ borderTop: 0 }}>
              <nav className="pagination-buttons mt-5">

                <ul className="pagination text-center">

                  {/* FIRST PAGE BUTTON */}
                  {current_page > 2 ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === 1 ? `item-disabled` : `` } `}
                           onClick={() => onPageClick(`first`, 1)} >
                          { loaderAt(`first`) ?
                              <Loading xs /> : <span className="pagn-btn">1</span> }
                        </a>
                      </li>
                  ) : null }

                  { showFormerDots ? (
                      <li className="page-item">
                        ...
                      </li>
                  ) : null }

                  {/* PREVIOUS PAGE BUTTON */}
                  { current_page !== 1 ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === 1 ? `item-disabled` : `` } `}
                           onClick={() => onPageClick(`previous`, +current_page - 1)} >
                          { loaderAt(`previous`) ?
                              <Loading xs /> : <span className="pagn-btn">{current_page - 1}</span> }
                        </a>
                      </li>
                  ) : null }

                  {/* CURRENT PAGE BUTTON */}
                  <li className="page-item">
                    <a className={`page-link item-disabled item-red ${ +current_page === 1 ? `item-disabled` : `` } `}>
                      <span className="pagn-btn">{current_page}</span>
                    </a>
                  </li>

                  {/* NEXT PAGE BUTTON */}
                  { (current_page !== last_page && total > 1) ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === last_page ? `item-disabled` : `` } `}
                           onClick={() => onPageClick(`next`, +current_page + 1)} >
                          { loaderAt(`next`) ?
                              <Loading xs /> : <span className="pagn-btn">{current_page + 1}</span> }
                        </a>
                      </li>
                  ) : null }

                  { showRearDots ? (
                      <li className="page-item">
                        ...
                      </li>
                  ) : null }

                  {/* LAST PAGE BUTTON */}
                  {(current_page !== last_page && current_page !== last_page - 1 && total > 1) ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === last_page ? `item-disabled` : `` } `}
                           onClick={() => onPageClick(`last`, last_page)} >
                          { loaderAt(`last`) ?
                              <Loading xs /> : <span className="pagn-btn">{last_page}</span>
                          }
                        </a>
                      </li>
                  ) : null }

                </ul>

              </nav>
            </td>
          </tr>

      );
    return null

}

export default  PaginationButtons;