export const MainState = {
  data: {
    sidebar: {
      open: false
    },
    filter_sidebar_open:false,
    order_filter_mode:``,
    isSpecificBranch:false,
    user_role:``,
    logged_in_user:{}
  }
}