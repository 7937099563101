import React from 'react';

class NoResultsFound extends React.Component
{
    render()
    {
        return (

            <div className="text-center mt-5 mb-5 no-results-found">No Results Found</div>

        )
    }
}
export default NoResultsFound;