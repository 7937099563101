const DefaultDeleteRequestListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_by:  'desc',
    sort_column: `id`,
    
    q: ``,
  },

  fields: [
    // { field: `id`, label: `ID`, sort: true },
    // { field: `username`, label: `Användarnamn`, sort: true },
    { field: `first_name`, label: `Förnamn`, sort: true },
    { field: `last_name`, label: `Efternamn`, sort: true },
    // { field: `role`, label: `Role`, sort: true },
    { field: `address`, label: `Adress`, sort: true },
    { field: `post_address`, label: `Postadress`, sort: true },
    { field: `postal_code`, label: `Postnummer`, sort: true },
    { field: `email`, label: `Email`, sort: true },
    { field: `personal_number`, label: `Personnummer`, sort: true },
    // { field: `samtycke`, label: `Samtycke`, sort: true },
    // { field: `company_name`, label: `Company`, sort: true },
    // { field: `state`, label: `State`, sort: true },
    // { field: `city`, label: `City`, sort: true },
    // { field: `postcode`, label: `Post Code`, sort: true },
    { field: `status`, label: `Status`, sort: true },

  ]
};

export const DeleteRequestListState = { data: DefaultDeleteRequestListState };