import axios from 'axios';
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loggedInUser, loginToken} from "../../../utils/LocalStorageUtils";
import getDeletionRequests from './deletion_requests_get';

function _success(success)
{
    return { type: 'DELETION_REQUEST_CONFIRM_SUCCESS', success };
}
function _error(error)
{
    return { type: 'DELETION_REQUEST_CONFIRM_ERROR', error };
}
function _processing(processing, id)
{
    return { type: 'DELETION_REQUEST_CONFIRM_PROCESSING', processing , id};
}

function deleteRequestConfirm( id )
{
    return ( dispatch ) =>
    {
        dispatch(_processing(true, id ));
        dispatch(_error(null ));
        dispatch(_success(null ));

        axios({
            url: apiBaseUrl(`requests/deletion/confirm/${id}`),
            method: "post",
            dataType: 'json',
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            }
            // data: values,
        })
          .then(function(res){
              dispatch(_success(res));
              dispatch(_processing(false));
              handleSuccess(res);
              dispatch(getDeletionRequests())
              
          }).catch(function(error){
              handleError( error );
              dispatch(_error(error));
              dispatch(_processing(false));
          });
    }
}


export default deleteRequestConfirm;