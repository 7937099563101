import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import getUsers from "./users_get";

function _success(success) {
    return {type: 'DELETE_USER_SUCCESS', success};
}

function _error(error) {
    return {type: 'DELETE_USER_ERROR', error};
}

function _processing(processing, id) {
    return {type: 'DELETE_USER_PROCESSING', processing, id};
}

function deleteUser(id) {
    return (dispatch) => {
        dispatch(_processing(true, id));
        axios({
            url: apiBaseUrl(`user/${id}`),
            method: "delete",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false, id));
                handleSuccess(res);
                dispatch( getUsers() );
            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false, id));
            handleError(error)
        });
    }
}

export default deleteUser;