import { emptyObject, isString, isArray, isObject } from './DataUtils'
import React from 'react';
import Alert from "react-s-alert";
import {toast} from "react-toastify";
import {ConfigErrorAlert, ConfigSuccessAlert, ConfigToastAlert } from "../Config";
import {logOut} from "./LocalStorageUtils";

export const handleError = error =>
{
  if ( error ) {
    if ( !error.response ) {
      if ( error.message ) {
        toast.error(`Error - ${error.message}`, ConfigToastAlert)
        // Alert.error(`Error ${ 500 } - ${ error.message }`, ConfigErrorAlert );
      }
    } else {
      let { response } = error;
      let { status, data } = response;
      if ( status && data ) {

        if ( data[0] === `<` )  {
          toast.error(` Server Error`, ConfigToastAlert );
        }
        else if ( status === 400 ) {
          if (isString(data.message)) {
            toast.error(` ${ data.message }`, ConfigToastAlert );

          } else if ( data.message ) {
            toast.error(` ${ data.payload.message }`, ConfigToastAlert );
          }
        }
        else if ( status === 401 ) {

          if (data.message && isString(data.message)) {
            toast.error(`  ${ data.message }`, ConfigToastAlert );

          } else if ( data.payload.message && isString( data.payload.message ) ) {
            toast.error(` ${ data.payload.message }`, ConfigToastAlert );
          }
          setTimeout(() => {
            logOut();
            window.location.href = '/login';
          }, 3000)
        }
        else if ( status === 403 ) {
          if (isString( data.message )) {
            toast.error(`${ data.message }`, ConfigToastAlert );
          } else {
            toast.error(`Forbidden action.`, ConfigToastAlert );
          }
          setTimeout(() => {
            logOut();
            window.location.href = '/login';
          }, 3000)

        }
        else if ( status === 404 ) {
          if (isString(data.message)) {
            toast.error(` ${ data.message }`, ConfigToastAlert );
          }
        }
        else if ( status === 409 ) {
          if (isString( data.message )) {
            toast.error(` ${ data.message }`, ConfigToastAlert );
          }
        }
        else if ( status === 500 ) {
          if ( data.message ) {
            if (isString( data.message )) {
              toast.error(`${ data.message }`, ConfigToastAlert );
            }
          } else if( data.payload.message) {
            if (isString( data.payload.message )) {
              toast.error(`${ data.payload.message }`, ConfigToastAlert );
            }
          }
        } else if( status === 422 ) {
          let message  = "";
          // let payload = error && error.response && error.response.data && error.response.data.payload;
          if( data && data.payload && data.payload.message ) {
            message  = data.payload && data.payload.message;
          } else if(data && data.errors) {
            message = data.errors;
          }else{
            message  = data && data.message;
          }
          let err_msg = ``;
          message && Object.keys( message ).map(key => {
            err_msg = message[key] || message[key][0];
            // toast.error(`${ err_msg }`, ConfigToastAlert );
          })
          toast.error(`${ status } - ${ err_msg }`, ConfigToastAlert );
        }
      }
    }
  }
}



export const handleSuccess = success =>
{
  // console.log(` <|  |> `, success)
  if(success) {
    if ( isString( success )) {
      toast.success(`Success ${ success }`, ConfigToastAlert );
    } else {
      if ( success.data ) {
        if(success.data.message) {
          toast.success(`${ success.data.message }`, ConfigToastAlert );
        } else {
          toast.success(`${ success.data.payload.message }`, ConfigToastAlert );
        }
      } else  {
        toast.success(`${ success.data.message }`, ConfigToastAlert );
      }
    }
  }
};

export const errorsList = (errors, field = null) =>
{
  let _errors = {};
  if ( errors && errors() ) {
    Object.keys(errors()).map(item => _errors[item] = errors(item));
    return field ? _errors[field] : _errors;
  }
};

export const errorsPresent = (errors) =>
{
  let verified = false;
  for (let key in errorsList(errors)) {
   /* if ( errorsList( errors, key )) {
      if ( isArray( errorsList( errors, key ))) {
        if ( errorsList(errors, key).length ) {
          verified = true;
          break;
        }
      } else if ( isObject( errorsList( errors, key ))) {
        Object.keys( errorsList( errors, key )).map( key1 => {
          if ( isArray( errorsList( errors, key )[key1])) {
            if ( errorsList(errors, key)[key1].length ) {
              verified = true;
            }
          }
        })
      }
    }*/
    if (errorsList(errors, key) &&
      errorsList(errors, key).length) {
      verified = true;
      break;
    }
  }
  return verified;
};

export const mergeServerValidationErrors = (errors, serverValidationErrors) =>
{
  if ( errors && serverValidationErrors )
    if (!emptyObject(serverValidationErrors))
      Object.keys(serverValidationErrors).map(field => {
        if (serverValidationErrors[field].length)
          serverValidationErrors[field].map(err =>
            errors[field] && errors[field].push({ step: 0, message: err })
          );
      });
  return errors;
};

export const stepHasError = ( step, errors ) =>
{
  let hasError = false;
  if ( String( step ) && errors ) {
    Object.keys( errors ).map( key => {
      if ( errors[key].length ) {
        errors[key].map( err => {
          if ( err.step === step ) hasError = true;
        })
      }
    })
  }
  return hasError;
}