import React  from 'react'
import store  from "../../../store"
import { Provider } from "react-redux"
// import Modal from 'react-modal'
import App from "./App";
import '../../../assets/scss/main.scss'

// Modal.setAppElement('#boot')

const Boot = () =>
{
  return (

    <div className="boot">

      <Provider store={store}>

        <App />

      </Provider>

    </div>
  );
};


export default Boot