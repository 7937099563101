import { combineReducers } from 'redux'

import app from './components/layout/App/App-Reducers'
import routes from './components/modules/route/route-reducers'
import loginForm from './components/modules/login/LoginForm-Reducer'
import main from './components/layout/Main/Main-Reducers'

import userLogin from "./ajax/reducers/user/user-login-post";
import userLogOut from "./ajax/reducers/user/user-logout-post";
import detailsUser from "./ajax/reducers/user/user-detail-get";

import userUpdate from "./ajax/reducers/user/user-put";
import userCreate from "./ajax/reducers/user/user-post";
import userApprove from "./ajax/reducers/user/user-approve-post";
import usersGet from "./ajax/reducers/user/users-get";
import userGet from "./ajax/reducers/user/user-get";
import userStatsGet from "./ajax/reducers/user/user-stats-get";
import userDelete from "./ajax/reducers/user/user-delete";
import myAccountUpdate from "./ajax/reducers/user/my-account-put";
import accountForm from "./components/modules/account/AccountForm-Reducer";
import userList from "./components/modules/users/list/UserList-Reducer";
import userForm from "./components/modules/users/form/UserForm-Reducer";
import updateBulkUsers from "./ajax/reducers/user/bulk-users-import";
import bulkUserUpdateForm from "./components/modules/users/bulk-update-form/BulkUserUpdateForm-Reducer";
// import passwordForm from "./components/modules/account/password/PasswordForm-Reducer";

import rolesGet from "./ajax/reducers/role/roles-get";
import roleGet from "./ajax/reducers/role/role-get";
import roleCreate from "./ajax/reducers/role/role-post";
import roleUpdate from "./ajax/reducers/role/role-put";
import roleDelete from "./ajax/reducers/role/role-delete";
import roleList from "./components/modules/roles/list/RoleList-Reducer";
import roleForm from "./components/modules/roles/form/RoleForm-Reducer";

import getDeletionRequestsReducer from './ajax/reducers/deletion-request/deletion-requests-get'
import postDeletionRequestConfirmReducer from './ajax/reducers/deletion-request/deletion-request-confirm-post'
import deleteRequestListReducer from './../src/components/modules/delete-request/list/DeleteRequestList-Reducer'
export default combineReducers({

  app, routes, loginForm, main,

  userLogin, detailsUser,userLogOut,

  userUpdate, accountForm, usersGet, userGet,userCreate, userDelete, userList,userForm, myAccountUpdate, updateBulkUsers,
  bulkUserUpdateForm, userApprove, userStatsGet,
  //passwordForm,

  rolesGet, roleList, roleForm, roleCreate, roleUpdate, roleDelete, roleGet,
  getDeletionRequestsReducer, postDeletionRequestConfirmReducer, deleteRequestListReducer
})