import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import store from '../../../store';

import getRoles from "./roles_get";

function _success(success)
{
    return { type: 'POST_ROLE_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_ROLE_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_ROLE_PROCESSING', processing };
}

function createRole( values )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));

        axios({
            url: apiBaseUrl(`role`),
            method: "post",
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            handleSuccess(res);
            store.dispatch(getRoles());

        }).catch(function(error)
        {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError( error )
        });
    }
}
export function createRoleReset()
{
    return dispatch => dispatch({ type: `POST_ROLE_RESET` })
}


export default createRole;