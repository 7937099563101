import React, {useState, useEffect} from 'react';
import {names, ucFirst, unslufigy} from '../../../utils/StringUtils'
import Div from "../Div/Div";
import Badge from "../../shared/badges/Badge";
import MyIcon from "../../shared/icons/MyIcon";
import uploadIcon from "../../../assets/images/cloud_upload.svg";

const UploadImageNew = (props) => {
    const {value, name, label, errors, onChange, noLabel, labelIcon,keys, supportedFormats, required,noFileRemoval, id, touched, error, multiple, onRemoveImage, uploadType } = props;

    const [files, setFiles] = useState([]);

    const hasError = error && touched;

    const Label = () => {
        if (label) return (
            <Div className={names(`label-text`)}>
                {label} {hasError ? error + '.' : null}
            </Div>
        );
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return (
                    <Div className={names(`label-text`)}>
                        {_label} {hasError ? error + '.' : null}
                    </Div>
                )
            }
        }
        return null
    }

    return (
        <div className={names(`form-group`, hasError && `has-error`)}>
            { hasError && <Label/>}
            <div className="input-file-wrapper">
                <input type="file"
                       className="form-control"
                       key={keys}
                       multiple={ multiple }
                       onChange={e => onChange({ target: { name, value: e.target.files, id, files: files.concat(Array.from(e.target.files))  } })}/>
                <div className="text-wrapper">
                    <img src={uploadIcon} alt=""/>
                    <p>Bifoga bild/fil</p>
                    {supportedFormats ?
                        <p className="fileType">
                            Endast {supportedFormats}-format är tillåtna.
                        </p>
                        : null}
                </div>
            </div>
            {  value ?
                <ul className="file-list">
                    {
                        value && Object.keys(value).map(key => {

                            return (

                                <li key={key}>
                                    <Badge type="secondary" style={{marginRight: 10}}>
                                        {value[key].name}
                                        {!noFileRemoval && (
                                            <MyIcon type="times"
                                                // onClick={() => removeFile(value[key].name)}
                                                    onClick={e => onRemoveImage({ target: { name, value: ``, id, files: ``  } })}
                                                    className="remove-file"
                                            />
                                        )}
                                    </Badge>
                                </li>
                            )

                        })
                    }
                </ul>
                : null}



        </div>
    )
}
export default UploadImageNew;