import getUsers from '../../../../ajax/actions/user/users_get'

export function toggleUserListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:'TOGGLE-USER-LIST-FILTER', name, value});
    if ( e.target.name === `page` || name === `sort_column` ) {
      dispatch( getUsers() )
    }
  }
}

export function toggleUserPaginationListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:'TOGGLE-USER-LIST-FILTER', name, value});
    dispatch(getUsers())
  }
}

export function setDefaultUserFilters()
{
  return function (dispatch) {
    dispatch({type:'SET-DEFAULT-USER-FILTERS'});
  }
}

export function setUserStatus(status)
{
  return function (dispatch) {
    dispatch({type:'SET-USER-STATUS', status});
  }
}