import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import {clone} from "../../../utils/DataUtils";
import store from "../../../store";
// import {
//     cancelUserFormDispatch,
//     userFormServerValidationErrors
// } from "../../../components/pages/users/form/UserForm-Action";
import getUsers from "./users_get";

function _success(success)
{
    return { type: 'PUT_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_USER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_USER_PROCESSING', processing };
}

function updateUser( values, id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        if ( !values.password ) delete values.password;
        if ( !values.member ) delete values.member;
        if ( !values.offk ) delete values.offk;

        axios({
            url: apiBaseUrl(`user/${id}`),
            method: "put",
            dataType: 'json',
            data: values,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function(res){
                dispatch(_success(res));

                store.dispatch(getUsers());
                handleSuccess( res  )

            }).catch(function(error){
                dispatch(_processing(false));
                dispatch(_error(error));
                handleError(error)

            });
    }
}

export function updateUserReset() {
    return dispatch => dispatch({type: `PUT_USER_RESET`})
}

export default updateUser;