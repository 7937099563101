import React from 'react';
import {Link} from "react-router-dom";
import swal from "sweetalert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import ActionCell from "../../../shared/table/ActionCell";
import { ucFirstAll} from "../../../../utils/StringUtils";
import Badge from "../../../shared/badges/Badge";
import deleteUser from "../../../../ajax/actions/user/user_delete";
import approveUser from "../../../../ajax/actions/user/user_approve_post";
import {useDispatch, useSelector} from "react-redux";
import {justDateCustomFormatted} from "../../../../utils/DateTimeUtils";

const UserTableCell = (props) => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { userApprove, userDelete } = reduxState;

    const deleteConfirm = (id) => {
        swal("Är du säker på att du vill radera denna användare? ", {
            buttons: {
                cancel: "Avbryt",
                catch: {
                    text: "Radera",
                    value: id
                }
            }
        }).then((value) => {
            if (value) dispatch(deleteUser(id))
        })
    };

    const statusConfirm = (status, id) => {
        // Är du säker på att du vill ${ status === 'approved' ? 'pending' : 'godkänna' } denna användare?
        swal(`Är du säker på att du vill ändra status på denna användare till ${ status === 'approved' ? '"Väntar"' : 'approve' }? `, {
            buttons: {
                cancel: "Avbryt",
                catch: {
                    text: "Bekräfta",
                    value: id
                }
            }
        }).then((value) => {
            if (value) dispatch(approveUser(id))
        })
    };

    const { url, data} = props;
    return (
        <>
            {/*<td className="clickable-col"><Link to={`${url}/${data.id}/update`}>{ data.username }</Link></td>*/}
            <td >{ucFirstAll( data.first_name.trim() )} </td>
            <td > { ucFirstAll( data.last_name.trim() )}</td>
            <td className="note-width">{data.address}</td>
            <td className="note-width">{data.post_address}</td>
            <td>{data.postal_code || '-'}</td>
            <td>{data.email}</td>
            <td>{data.personal_number}</td>
            <td>{data.offk}</td>
            <td>{data.forb}</td>
            <td>{data.grad}</td>
            <td className={ data.note ? `note-width` : `` }>{data.note || '-'}</td>
            { props.mode === 'approved' && <td >{ justDateCustomFormatted( data.approval_date ) || '-'}</td> }
            <td>{data.role && data.role.slug === 'avliden' ? <Badge type="danger">{data.role.title}</Badge>  :
                <Badge type={data.status}>{data.status === 'approved' ? 'Godkänd' : 'Väntar' }</Badge>}</td>
            <td className="actions">
                <ActionCell id={data.id}
                            status={ data.status }
                            url={url}
                            role={data.role}
                            onDelete={ deleteConfirm }
                            view statusChange
                            onStatusConfirm={ statusConfirm }
                            deleteReducer = { userDelete }
                            approveReducer = { userApprove }
                />
            </td>
        </>
    )
}

export default UserTableCell;