import React from 'react';
import Page from "../../shared/pages/Page";
import {useRouteMatch} from "react-router";

const JourNummer = (props) =>
{
console.log(` <| match url |> `, useRouteMatch())
     return (
        <Page title="Jour Nummer">
          <div className="row">
            <div className="offset-lg-1 col-lg-7">
              <h2>Kontorstid är Mån-Fredag 07:00- 17:00<br/>
                Vid prio 1 jobb efter dessa tider ring nedan:</h2>

              <h5>Eljour Stockholm</h5>
              <p>Journummer 08 704 20 00 <br />
                Stockholms Eljour</p>

              <h5>Rörjour Stockholm</h5>
              <p>Journummer 08-6049050<br/>
                Holmans Rörjour</p>

              <h5>Eljour Malmö</h5>
              <p>Journummer 0703-496240<br/>
                Elakuten i Malmö</p>

              <h5>Rörjour Malmö</h5>
              <p>Journummer 0708-89 8​1 00<br/>
                Rörmöntoren Syd</p>

              <h5>Kyljour Stockholm</h5>
              <p>0707 95 70 65 <br/>
              Klimatkonsult</p>

              <h5>Kyljour Malmö</h5>
              <p>0703 - 39 05 95 <br/>
              BEMT</p>

            </div>
          </div>
        </Page>
     )
}

export default  JourNummer;