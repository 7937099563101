import React, { useEffect, useRef } from 'react';
import * as Yup from "yup";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import SaveButton from "../../shared/buttons/SaveButton";
import updateMyAccount, {_accountReset} from "../../../ajax/actions/user/my_account_put";
import TextField from "../../common/form/TextField";
import {setAccountDataForUpdate} from "./AccountForm-Action";
import PasswordField from "../../common/form/PasswordField";
import {endOfURL} from "../../../utils/URLUtils";
import SelectField from "../../common/form/SelectField";
// import {countryListAllIsoData} from "../../../static-data/CountryList";
import userDetails from "../../../ajax/actions/user/user_detail_get";
// import getRoles from "../../../ajax/actions/role/roles_get";
import {samtyckeOption} from "../../../static-data/LimitOptions";
// import {yearList} from "../../../static-data/UserData";
import TextAreaField from "../../common/form/TextAreaField";

const AccountForm = () => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const {detailsUser, accountForm, userUpdate, myAccountUpdate, rolesGet } = reduxState;

    let {payload} = accountForm;

    useEffect(() => {
        dispatch( userDetails() )
        // dispatch( getRoles(100) );
    }, []);

    useEffect( () => {
        if ( myAccountUpdate && myAccountUpdate.success ) {
            dispatch( _accountReset() );
        }
    }, [userUpdate.success]);

    useEffect(() => {
        detailsUser.success && dispatch(setAccountDataForUpdate());
    }, [detailsUser.success]);


    let processing = myAccountUpdate.processing;

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required(` krävs`),
        last_name: Yup.string().required(` krävs`),
        username: Yup.string().required(` krävs`),
        role_id: Yup.string().required(` krävs`),
        // password: !id && Yup.string().required(` krävs`),
        email: Yup.string().email(` must be valid email`).required(' krävs'),
        address:Yup.string().required(` krävs`),
        post_address:Yup.string().required( ` krävs`),
        personal_number:Yup.number(`måste minst vara 10 siffror`),
        // grad:Yup.string().required(` krävs`),
        // forb:Yup.string().required(` krävs`),
        // offk:Yup.string().required(` krävs`),
        // stand:Yup.string().required(` krävs`),
        // samtycke:Yup.string().required(` krävs`),
        // company_name:Yup.string().required(` krävs`),
        // postcode:Yup.string().required(` krävs`),
        // city:Yup.string().required(` krävs`),
        // state:Yup.string().required(` krävs`),
        // country:Yup.string().required(` krävs`),
        password_confirmation: Yup.string().when("password", {
            is: val => (!!(val && val.length > 0)),
            then: Yup.string().required('krävs').oneOf(
                [Yup.ref("password")],
                "Lösenorden matchar inte"
            )
        })
    });

    const {handleChange, handleSubmit, errors, touched, values, setFieldValue} = useFormik(
        {
            initialValues: payload,
            enableReinitialize: true,

            validationSchema: validationSchema,

            onSubmit: (values) => {
                dispatch(updateMyAccount(values))
            }
        })

    const handleChangeSamtycke = (e) => {
        let { name, value } = e.target;
        let isTrueSet = (value === 'true');
        setFieldValue( `samtycke`, isTrueSet )
    }

    // console.log(` <| errors |> `, errors )
    // let roles = rolesGet && rolesGet.success && rolesGet.success.data.data;

    return (
        <div className="row">
            <div className="offset-lg-1 col-lg-7 col-md-12">

                {/*<AccountFormWorker/>*/}

                <h2>Mitt konto</h2>

                <form onSubmit={handleSubmit}>

                    <TextField name="first_name"
                               value={values.first_name}
                               error={errors && errors.first_name}
                               touched={touched.first_name}
                               onChange={handleChange}
                               label="Förnamn"
                               />

                    <TextField name="last_name"
                               value={values.last_name}
                               error={errors && errors.last_name}
                               touched={touched.last_name}
                               onChange={handleChange}
                               label="Efternamn"
                               />

                    <TextField name="email"
                               value={values.email}
                               error={errors && errors.email}
                               touched={touched.email}
                               disabled
                               onChange={handleChange}
                               label="E-post"
                               />

                    <TextField name="username"
                               value={values.username}
                               error={errors && errors.username}
                               touched={touched.username}
                               onChange={handleChange}
                               label="Användarnamn"
                               />

                    <PasswordField name="password"
                                   value={values.password}
                                   refer={useRef()}
                                   error={errors && errors.password}
                                   touched={touched.password}
                                   label={endOfURL() === `update` ? `Lösenord (bara om du önskar att uppdatera detta)` : "Lösenord"}
                                   placeholder={endOfURL() === `update` ? `Lösenord (bara om du önskar att uppdatera detta)` : "Lösenord"}
                                   onChange={handleChange}/>

                    <PasswordField name="password_confirmation"
                                   value={values.password_confirmation}
                                   refer={useRef()}
                                   error={errors && errors.password_confirmation}
                                   touched={touched.password_confirmation}
                                   label="Bekräfta lösenord"
                                   onChange={handleChange}/>

                    {/*<SelectField name="country"
                                 options={countryListAllIsoData}
                                 onChange={handleChange}
                                 label="Country"
                                 value={values.country}
                                 _label="name"
                                 _value="name"

                                 error={errors && errors.country}
                                 touched={touched.country}
                    />

                    <TextField name="state"
                               value={values.state}
                               error={errors && errors.state}
                               touched={touched.state}
                               onChange={handleChange}/>

                    <TextField name="city"
                               value={values.city}
                               error={errors && errors.city}
                               touched={touched.city}
                               onChange={handleChange}/>

                    <TextField name="postcode"
                               value={values.postcode}
                               error={errors && errors.postcode}
                               touched={touched.postcode}
                               onChange={handleChange}/>*/}

                    <TextField name="address"
                               value={values.address}
                               error={errors && errors.address}
                               touched={touched.address}
                               onChange={handleChange}
                               label="Adress"
                               />

                    <TextField name="post_address"
                               value={values.post_address}
                               error={errors && errors.post_address}
                               touched={touched.post_address}
                               onChange={handleChange}
                               label="Postadress"
                               />

                    <TextField name="personal_number"
                               value={values.personal_number}
                               error={errors && errors.personal_number}
                               touched={touched.personal_number}
                               onChange={handleChange}
                               label="Postadress"
                               />

                    <TextField name="grad"
                               value={values.grad}
                               error={errors && errors.grad}
                               touched={touched.grad}
                               onChange={handleChange}/>

                    <TextField name="forb"
                               value={values.forb}
                               error={errors && errors.forb}
                               touched={touched.forb}
                               onChange={handleChange}
                               label="Förb"
                               />

                    <TextField name="offk"
                               value={values.offk}
                               error={errors && errors.offk}
                               touched={touched.offk}
                               onChange={handleChange}/>

                    <TextField name="stand"
                               value={values.stand}
                               error={errors && errors.stand}
                               touched={touched.stand}
                               onChange={handleChange}/>

                    {/*<SelectField name="samtycke"
                                 options={samtyckeOption}
                                 onChange={handleChangeSamtycke}
                                 label="Samtycke"
                                 value={values.samtycke}
                                 _label="label"
                                 _value="value"

                                 error={errors && errors.samtycke}
                                 touched={touched.samtycke}
                    />*/}

                    {/*<SelectField name="member"
                                 options={yearList()}
                                 onChange={handleChange}
                                 label="Year"
                                 value={values.member}
                                 _label="name"
                                 _value="value"

                                 error={errors && errors.member}
                                 touched={touched.member}
                    />*/}

                    <TextAreaField name="note"
                                   value={values.note}
                                   error={errors && errors.note}
                                   touched={touched.note}
                                   onChange={handleChange}
                                   label="Notering"
                                   />

                   {/* <TextField name="second_address_line"
                               value={values.second_address_line}
                               error={errors && errors.second_address_line}
                               touched={touched.second_address_line}
                               onChange={handleChange}/>

                    <TextField name="company_name"
                               value={values.company_name}
                               error={errors && errors.company_name}
                               touched={touched.company_name}
                               onChange={handleChange}/>*/}

                    <TextField name="role"
                                 label="Status"
                                 value={values.role}
                                 disabled
                    />

                    <div className="text-center">
                        <SaveButton btnClass={`text-uppercase mt-5`} type="submit" processing={processing}/>
                    </div>
                </form>


            </div>
        </div>

    )
}

export default AccountForm;