import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { limitOptions, statusOption} from '../../../../static-data/LimitOptions'
import {toggleDeleteRequestListFilter, setDefaultDeleteRequestFilters, setDeleteRequestStatus} from './DeleteRequestList-Actions'


import {useHistory} from "react-router";
import SelectField from "../../../common/form/SelectField";
import TextField from "../../../common/form/TextField";
import getDeletionRequests from '../../../../ajax/actions/deletion-request/deletion_requests_get';

const DeleteRequestFilters = (props) => {
    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const history = useHistory();
    const {deleteRequestListReducer} = reduxState;

    useEffect(() => {

        dispatch( getDeletionRequests())
    }, [])


    const toggleFilter = (e) => {
        dispatch(toggleDeleteRequestListFilter(e, history));
    };

    const resetFilter = (e) => {
        dispatch(setDefaultDeleteRequestFilters(e, history));
        // dispatch(setDeleteRequestStatus(props.status));
        dispatch(getDeletionRequests());
    };

    const applyFilters = (e) => {
        dispatch(getDeletionRequests());
    };
    const {limit, q,  } = deleteRequestListReducer.data.params;

    return (

        <div className="table-filter mb-5">
            <div className="row">
                <div className="col custom-col">
                    <SelectField name="limit"
                                 noFormGroup
                                 options={limitOptions}
                                 _value="total"
                                 _label="label"
                                 value={limit}
                                 notSearchable
                                 placeholder="-- Limit --"
                                 onChange={toggleFilter}
                                 label="Begränsning"/>
                                 
                </div>

                

                {/*<div className="col custom-col">
                    <SelectField name="status"
                                 noFormGroup
                                 options={statusOption}
                                 _value="value"
                                 _label="label"
                                 value={status}
                                 notSearchable
                                 placeholder="-- Status --"
                                 onChange={toggleFilter}/>
                </div>*/}

                <div className="col custom-col">
                    <TextField name="q"
                               label="Sök"
                               placeholder="Nyckelord"
                               onChange={toggleFilter}
                               value={q}
                    />
                </div>


                {/*<div className="col custom-col">
                    <div className="text-right">
                        <button className="btn-theme btn-gray m-auto" onClick={applyFilters}>Filter</button>
                    </div>
                </div>
                <div className="col custom-col">
                    <div className="text-right mt-1">
                        <button className="btn-theme btn-gray m-auto" onClick={resetFilter}>Reset</button>
                    </div>
                </div>*/}

                <div className="col custom-col button-col">
                    <div className="button-cluster">
                        <button className="btn-theme btn-gray mr-2" onClick={applyFilters}>Filtrera</button>
                        <button className="btn-theme btn-gray " onClick={resetFilter}>Återställ</button>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default DeleteRequestFilters;