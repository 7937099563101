import React, {useEffect} from 'react';
import { useDispatch} from 'react-redux';
import getUsers from "../../../../ajax/actions/user/users_get";
import store from '../../../../store';
import getRoles from "../../../../ajax/actions/role/roles_get";
import { setDefaultUserFilters, setUserStatus } from "./UserList-Actions";

const UsersWorker = (props) => {

    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDefaultUserFilters());
        dispatch(setUserStatus(props.status));
        store.dispatch(getUsers());
        store.dispatch(getRoles());
    }, []);

    return null
}

export default UsersWorker;