import { RoleFormState } from './RoleForm-State'

export default function roleFormReducer(state = RoleFormState, action) {

  let { payload } = { ...state };
  let {  roles } = action
  switch (action.type) {

    case 'SET-ROLE-DATA-FOR-UPDATE':
      payload = {
        title: roles.title,
        slug: roles.slug,
      };
      return { ...state, payload };

    case 'SET-DEFAULT-ROLE-FORM':
        payload = {
          title:``,
          slug:``,
      }
      return { ...state, payload }
    default: return state
  }
}

