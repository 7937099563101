import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";

import {apiBaseUrl, basePath} from "../../../../utils/URLUtils";
import Badge from "../../../shared/badges/Badge";
import Loading from "../../../shared/loaders/Loading";
import {fullDate, justDate, justDateCustomFormatted} from "../../../../utils/DateTimeUtils";
import Page from "../../../shared/pages/Page";
import {loginToken} from "../../../../utils/LocalStorageUtils";
import getUser, {getUserReset} from "../../../../ajax/actions/user/user_get";
import {ucFirst, ucFirstAll} from "../../../../utils/StringUtils";

const UserDetail = () => {

    const reduxState = useSelector(state => state);
    const {userGet} = reduxState;
    let dispatch = useDispatch();

    let {id} = useParams();

    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        dispatch(getUser(id));

        return () => {
            dispatch(getUserReset());
        }
    }, []);

    useEffect(() => {
        if (getUser.success && initialLoading) {
            setInitialLoading(false);
        }
    }, [getUser.success])

    let userData =
        userGet.success &&
        userGet.success.data;

    // console.log(` <| userData |> `, userData)
    if (userData) {
        return (
            <Page title="Användardetaljer">
                <div className={`row detail-page ${userGet.processing ? `item-disabled` : ``}`}>
                    <div className="col-xl-8 offset-xl-1 col-lg-8 col-md-8 order-md-0 order-1 mt-3 mt-md-0">
                        {
                                <h2>
                                    Användardetaljer : { userData.username } {userGet.processing && <Loading marginLeft={20}/>}
                                </h2>
                        }

                        {/*<h4>{userData.description}</h4>*/}

                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-6">
                                <div className="item-desc">
                                    <h6>FÖRNAMN</h6>
                                    <p>{ ucFirstAll( userData.first_name.trim() ) } </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-6">
                                <div className="item-desc">
                                    <h6>EFTERNAMN</h6>
                                    <p>{ ucFirstAll( userData.last_name.trim() ) }</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-6">
                                <div className="item-desc">
                                    <h6>E-POST</h6>
                                    <p>{userData.email}</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-6">
                                <div className="item-desc">
                                    <h6>Postnummer</h6>
                                    <p>{userData.postal_code || 'N/A'}</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-6">
                                <div className="item-desc">
                                    <h6>ANVÄNDAREN SKAPAD</h6>
                                    <p>{justDateCustomFormatted(userData.member || userData.created_at)}</p>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-12 col-md-6">
                                <div className="item-desc">
                                    <h6>STATUS</h6>
                                    <p>{userData.role && userData.role.slug === 'avliden' ? <Badge type="danger">{userData.role.title}</Badge>  : <Badge type={userData.status}>{userData.status === 'approved' ? 'Godkänd' : 'Väntar'}</Badge>}</p>
                                </div>
                            </div>
                            {userData.approval_date && <div className="col-xl-6 col-lg-12 col-md-6">
                                <div className="item-desc">
                                    <h6>DATUM VID GODKÄNNANDE</h6>
                                    <p>{justDateCustomFormatted( userData.approval_date )}</p>
                                </div>
                            </div> }

                            <div className="col-sm-12 col-md-12">
                                <div className="item-desc">
                                    <h6>ADRESS</h6>
                                    <p>{ userData.address }</p>
                                </div>
                            </div>

                            { userData.secondary_address_line && ( <div className="col-sm-12 col-md-12">
                                    <div className="item-desc">
                                        <h6>ADRESS</h6>
                                        <p>{ userData.secondary_address_line }</p>
                                    </div>
                            </div> )}

                            <div className="col-sm-12 col-md-12">
                                <div className="item-desc">
                                    <h6>POSTADRESS</h6>
                                <p>{ userData.post_address }</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <div className="item-desc"><h6>GRAD</h6>
                                <p>{ userData.grad || '-' }</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <div className="item-desc"><h6>FÖRB</h6>
                                <p>{ userData.forb || '-' }</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <div className="item-desc">
                                    <h6>OFFK</h6>
                                <p>{ userData.offk || '-' }</p>
                                </div>
                            </div>
                            {/*<div className="col-sm-12 col-md-12">
                                <h6>STAND</h6>
                                <p>{ userData.stand }</p>
                            </div>*/}
                            {/*<div className="col-sm-12 col-md-12">
                                <h6>SAMTYCKE</h6>
                                <p><Badge type={userData.samtycke === 1 ? 'done':'warning'}>{userData.samtycke === 1 ? 'TRUE' : 'FALSE'}</Badge></p>
                            </div>*/}

                            { userData.note && <div className="col-sm-12 col-md-12">
                                <div className="item-desc">
                                    <h6>NOTERING</h6>
                                    <p>{userData.note}</p>
                                </div>
                            </div> }

                            <div className="col-sm-12 col-md-12">
                                <div className="item-desc">
                                    <h6>PERSON NUMMER</h6>
                                    <p>{userData.personal_number}</p>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <div className="item-desc">
                                    <h6>BETALNINGSSTATUS</h6>
                                    <p><Badge type={userData.payment_status === "unpaid" ? "danger" : "success"}>{userData.payment_status === "paid" ? "Betald" : "Obetald"}</Badge></p>
                                    {/*<p>{ucFirst(userData.payment_status)}</p>*/}
                                </div>
                            </div>

                            {/*{ userData.member && <div className="col-sm-12 col-md-12">
                                <h6>Member Year</h6>
                                <p>{userData.member}</p>
                            </div> }*/}
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-4 col-md-3">
                        <div className="btn-cta-wrapper">

                            <Link to={`/users/create`} className="btn-theme btn-success">
                                Skapa ny användare
                            </Link>
                            <Link to={`/users/${userData.id}/update`} className="btn-theme btn-warning mt-2">
                                    Redigera användare
                            </Link>

                        </div>
                    </div>
                </div>
            </Page>
        )
    } else if (userGet.processing) {
        return (
            <div className="text-center mt-5">
                <Loading lg/>
            </div>
        )
    } else {
        return null
    }
}

export default UserDetail;