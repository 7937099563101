import React, {useState, useEffect} from 'react';

import {Link} from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loading from "../../../shared/loaders/Loading";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";

import NoResultsFound from "../../../shared/folder/NoResultsFound";
import Page from "../../../shared/pages/Page";
import TableHead from "../../../shared/table/TableHead";

import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";

import { toggleDeleteRequestListFilter, toggleDeleteRequestPaginationListFilter } from './DeleteRequestList-Actions';
import DeleteRequestFilters from './DeleteRequestFilters';
import DeleteRequestTableCell from './DeleteRequestTableCell';

const DeleteRequests = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [ list, setList] = useState([])

    let dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { getDeletionRequestsReducer, deleteRequestListReducer, postDeletionRequestConfirmReducer } = reduxState;

    useEffect(() => {
        if (getDeletionRequestsReducer.success && initialLoading) {
            setInitialLoading(false);
        }

        if (getDeletionRequestsReducer.success) {
            
            let _list =
                getDeletionRequestsReducer.success &&
                getDeletionRequestsReducer.success.data.data;
            setList(_list)
        }
    }, [getDeletionRequestsReducer.success])


    const toggleSort = (field) => {
        dispatch(toggleDeleteRequestListFilter({
            target: {name: `sort_column`, value: field}
        }))
    };

    let {processing} = getDeletionRequestsReducer;
    let { fields, params } = deleteRequestListReducer.data;

    

    let current_page = getDeletionRequestsReducer.success && getDeletionRequestsReducer.success.data && getDeletionRequestsReducer.success.data.current_page;
    let last_page = getDeletionRequestsReducer.success && getDeletionRequestsReducer.success.data && getDeletionRequestsReducer.success.data.last_page;
    let total = getDeletionRequestsReducer.success && getDeletionRequestsReducer.success.data && getDeletionRequestsReducer.success.data.total;
    let pages = {current_page, last_page, total};

    
    let {url} = useRouteMatch();
    

    return (

        <Page list title="Begäran om borttagning av konto">

            

            <div className="page-title">
                <div className="title-button-block d-flex align-items-center flex-wrap">
                    <h2 className="mr-4">Begäran om borttagning av konto {processing && <Loading sm marginLeft={20}/>}</h2>
                </div>
            </div>

            <DeleteRequestFilters status="approved" />

            <div className="table-dashboard table-small">
                <div className="table-responsive">
                    <table className="table">

                        <TableHead fields={fields}
                                   params={params}
                                   onClick={toggleSort}/>

                        <tbody>
                        {
                            list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <DeleteRequestTableCell  data={l} />
                                    </tr>
                                )
                            }) : null
                        }
                        </tbody>

                        {list && list.length ? (
                            <tfoot>
                            <PaginationButtons pages={pages}
                                               processing={processing}
                                               fetchAction={toggleDeleteRequestPaginationListFilter}/>
                            </tfoot>
                        ) : null}

                    </table>
                    {!initialLoading && (!list || !list.length) && <NoResultsFound/>}
                    {initialLoading && <div className="text-center"><Loading/></div>}

                </div>
            </div>
        </Page>
    )
}

export default DeleteRequests;