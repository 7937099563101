import axios from 'axios'
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from "../../../utils/LocalStorageUtils";
import {handleError} from "../../../utils/ErrorUtils";

function _success(success) {
    return {type: 'GET_USER_STATS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_USER_STATS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_USER_STATS_PROCESSING', processing};
}

function getUserStats() {
    return (dispatch,) => {
        dispatch(_processing(true));

        axios({
            url:apiBaseUrl(`user/stats`),
            method:'get',
            headers:{
                'Authorization': 'Bearer ' + loginToken()
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });
    }
}
export function getUserStatsReset()
{
    return dispatch => dispatch({ type: `GET_USER_STATS_RESET` })
}
export default getUserStats;