import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import {loginToken} from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import store from '../../../store';
import getUsers from "../user/users_get";
/*import {
    bulkUserUpdateFormServerValidationErrors,
    cancelBulkUserUpdateFormDispatch,
    setDefaultBulkUserUpdateForm
} from "../../../components/pages/users/bulk-update-form/BulkUserUpdateForm-Action";*/

function _success(success) {
    return {type: 'BULK_USERS_UPDATE_SUCCESS', success};
}

function _error(error) {
    return {type: 'BULK_USERS_UPDATE_ERROR', error};
}

function _processing(processing) {
    return {type: 'BULK_USERS_UPDATE_PROCESSING', processing};
}

function _onProgress(progress) {
    return {type: 'BULK_USERS_UPDATE_PROGRESS', progress};
}

function bulkUsersUpdate( values, clearOnly )
{
    return (dispatch, getState) => {

        if ( clearOnly ) {
            dispatch(_error(null));
            dispatch(_success(null));
            dispatch(_processing(false));
            dispatch( _onProgress( 0 ) )

        } else {

            dispatch(_processing(true));
            dispatch(_success(null));
            dispatch(_error(null));
            dispatch( _onProgress( 0 ) )

            let {bulkUsersUpdateForm} = getState();
            // console.log(` <| values |> `, values)
            let formData = new FormData();

            formData.append(`data_file`, values.data_file[0]);

            axios({
                url: apiBaseUrl(`import/user`),
                method: "post",
                data: formData,
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + loginToken()
                },
                onUploadProgress:progress => {
                    // console.log(` <| progress |> `, progress) },
                    const {loaded, total} = progress;
                    const percentageProgress = Math.floor((loaded / total) * 100)
                    dispatch(_onProgress(percentageProgress))
                    // console.log(` <| percentageProgress |> `, percentageProgress)
                }

            })
                .then(function (res) {
                    dispatch(_success(res));
                    dispatch(_processing(false));
                    handleSuccess(res);

                })
                .catch(function (error) {
                    dispatch(_error(error));
                    // console.log(` <| error |> `, {error})
                    dispatch(_processing(false));
                    handleError(error);
                });
        }

    }
}
export function bulkUserUpdateReset()
{
    return dispatch => dispatch({ type: `BULK_USERS_UPDATE_RESET` })
}

export default bulkUsersUpdate;