import { BulkUserUpdateFormState } from './BulkUserUpdateForm-State'

export default function bulkUserUpdateFormReducer(state = BulkUserUpdateFormState, action) {

  let { data } = { ...state };
  switch (action.type) {

    case 'SET-DEFAULT-BULK-USERS-UPDATE-FORM':
      data = {
        payload:{
          data_file: ``
        },
        files: [],
      }
      return { ...state, data }

    default: return state
  }
}