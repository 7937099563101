import getDeletionRequests from "../../../../ajax/actions/deletion-request/deletion_requests_get";


export function toggleDeleteRequestListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:'TOGGLE-DELETE-REQUEST-LIST-FILTER', name, value});
    if ( e.target.name === `page` || name === `sort_column` ) {
      dispatch( getDeletionRequests() )
    }
  }
}

export function toggleDeleteRequestPaginationListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:'TOGGLE-DELETE-REQUEST-LIST-FILTER', name, value});
    dispatch(getDeletionRequests())
  }
}

export function setDefaultDeleteRequestFilters()
{
  return function (dispatch) {
    dispatch({type:'SET-DEFAULT-DELETE-REQUEST-FILTERS'});
  }
}

export function setDeleteRequestStatus(status)
{
  return function (dispatch) {
    dispatch({type:'SET-DELETE-REQUEST-STATUS', status});
  }
}