import { UserListState } from './UserList-State'

export default function userListReducer(state = UserListState, action) {

  let { data } = { ...state };
  let { name, value, status, id } = action;

  switch (action.type) {

    case 'TOGGLE-USER-LIST-FILTER':
      data.params[ name ] = value;
      if ( name === `sort_by` || name === `sort_column` ) {
        if ( data.params.sort_column === value ) {
          data.params.sort_by = data.params.sort_by === `desc` ? `asc` : `desc`
        }
      }
      if ( name === `limit` ) {
        data.params.page = 1;
      }
      return { ...state, data };

    case 'TOGGLE-USER-FORM-MODAL':
      if ( data.modal.mode ) { // if open, close
        data.modal = { mode: ``, id: `` }

      } else { // if close, open
        data.modal.id = id || ``;
        data.modal.mode = id ? `update` : `create`;
      }
      return { ...state, data };

    case 'SET-USER-STATUS':
      data.params.status = status
      return { ...state, data };

    case 'SET-DEFAULT-USER-FILTERS':
      data = {
        payload: {
          initialLoading: true,
          flashMessage: null,
          error: "",
          success: "",
        },
        params: {
          limit: 25,
          page: 1,
          sort_by: 'desc',
          sort_column: `id`,
          q: ``,
          status:``,
          role_id: ``,
        },
        modal: {
          mode: ``,
          id: ``
        },
        fields: [
          // { field: `id`, label: `ID`, sort: true },
          // { field: `username`, label: `Användarnamn`, sort: true },
          { field: `first_name`, label: `Förnamn`, sort: true },
          { field: `last_name`, label: `Efternamn`, sort: true },
          // { field: `role`, label: `Role`, sort: true },
          { field: `address`, label: `Adress`, sort: true },
          { field: `post_address`, label: `Postadress`, sort: true },
          { field: `postal_code`, label: `Postnummer`, sort: true },
          { field: `email`, label: `E-post`, sort: true },
          { field: `personal_number`, label: `Personnummer`, sort: true },
          { field: `offk`, label: `Offk`, sort: true },
          { field: `forb`, label: `Förb`, sort: true },
          { field: `grad`, label: `Grad`, sort: true },
          { field: `note`, label: `Notering`, sort: true },
          { field: `approval_date`, label: `Godkänd`, sort: true },
          // { field: `samtycke`, label: `Samtycke`, sort: true },
          // { field: `company_name`, label: `Company`, sort: true },
          // { field: `state`, label: `State`, sort: true },
          // { field: `city`, label: `City`, sort: true },
          // { field: `postcode`, label: `Post Code`, sort: true },
          { field: `status`, label: `Status`, sort: true },
        ]
      }
      return { ...state, data };

    default: return state
  }
}
