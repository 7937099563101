import axios from 'axios';
import {apiBaseUrl} from "../../../utils/URLUtils";
import { loginToken } from '../../../utils/LocalStorageUtils'
import {handleError, handleSuccess} from '../../../utils/ErrorUtils'
import {clone} from "../../../utils/DataUtils";

function _success(success)
{
    return { type: 'POST_ACCOUNT_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_ACCOUNT_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_ACCOUNT_PROCESSING', processing };
}

function updateMyAccount( values )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        // let payload = {};
        // let { accountForm, userDetail, passwordForm } = getState();

        let _values = {...values};
        if( values.password === `` || values.password === undefined || values.password === null  ) {
            delete values.password;
            delete values.password_confirmation;
        }
        /*if ( !password_mode  ) {
            payload = clone( accountForm.payload );

        } else { // password mode
            payload = clone( userDetail.success );
            payload.password = passwordForm.data.payload.new_password;
        }*/
        const id = _values.id

        axios({
            url: apiBaseUrl(`user/${id}`),
            method: "put",
            dataType: 'json',
            data: _values ,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
            })
            .catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )

            });
    }
}

export function _accountReset() {
    return dispatch => dispatch({type: `POST_ACCOUNT_RESET`})
}

export default updateMyAccount;