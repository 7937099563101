export function setDefaultAccountForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-ACCOUNT-FORM'});
  }
}

export function setAccountDataForUpdate() {
  return function (dispatch, getState) {
    let { detailsUser } = getState();
    let accounts = detailsUser && detailsUser.success && detailsUser.success.data;
    if(accounts) {
      dispatch({type:'SET-ACCOUNT-DATA-FOR-UPDATE',  accounts});
    }
  }
}